import React from "react"
import Container from "../components/container"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"

export default () => (
    <Container>
        <Helmet>
          <title>Contact | An Exquisite Meal</title>
          <meta name="description" content="Contact the creators of An Exquisite Meal." />
        </Helmet>
        
        <div class="sub-container-page">
            <div class="body-content">
                <a href="/"><img src="../AnExquisiteMeal_headline.png" width="600" alt="An Exquisite Meal" /></a>
                <h2 class="headline">Send us an email.</h2>
                
                <div class="description">
                </div>

                <div class="form-box">                
                    <form name="contact" method="POST" netlify-honeypot="bot-field" data-netlify="true" action="/thank-you-contact/">
                        <input type="hidden" name="form-name" value="contact"/>
                        <p class="hidden">
                            <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                        </p>
                        <p><label>Your Name: <input type="text" name="name" /></label></p>
                        <p><label>Your Best Email: <input type="email" name="email" /></label></p>
                        <p>
                            <label>Message: <textarea name="message" rows="8" cols="50"></textarea></label>
                        </p>
                        <br />
                        <button type="submit" class="btn btn-green">Send</button>
                    </form>
                </div>
            </div>
        </div>
            

        <Footer>
        </Footer>
    </Container> 
    
)


